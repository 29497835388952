import { slugify } from './strings'
import { getImageUploadUrl, getPdfUploadUrl } from './urls'
import Cookie from './cookie'

export function extension (fileName) {
  return fileName.split('.').pop()
}

export function sanitize (fileName) {
  const fileExtension = extension(fileName)
  const fileNameWithoutExtension = fileName.replace('.' + fileExtension, '')

  return Date.now() + '-' + slugify(fileNameWithoutExtension) + '.' + fileExtension
}

export function isImage (fileName) {
  return fileName && fileName.match(/\.(jpeg|jpg|gif|png)$/) != null
}

export function toBytes (formattedSize) {
  const lowerCased = formattedSize.toLowerCase()
  const supportedMeasures = {
    mb: sizeInMB => sizeInMB * 1024 * 1024
  }

  const eligibleMeasures = Object.keys(supportedMeasures)
          .filter(measure => lowerCased.endsWith(measure))

  if (eligibleMeasures.length === 0) {
    throw new Error(`Could not convert ${formattedSize} to bytes`)
  }

  const measure = eligibleMeasures[0]
  const convertFunction = supportedMeasures[measure]

  const size = lowerCased.substr(0, lowerCased.indexOf(measure))
  return convertFunction(Number(size))
}

export function uploadImage (file, temporary = false, filename) {
  const data = new window.FormData()
  data.append('image', file, filename)

  if (temporary) {
    data.append('temporary', 'true')
  }

  return fetch(getImageUploadUrl(), {
    method: 'POST',
    body: data,
    headers: {
      Authorization: Cookie.load('Authorization')
    }
  }).then((response) => {
    return response.json()
  })
}

export async function uploadPdf (file, temporary = false, filename) {
  const data = new window.FormData()
  data.append('image', file, filename)

  if (temporary) {
    data.append('temporary', 'true')
  }

  let response = await fetch(getPdfUploadUrl(), {
    method: 'POST',
    body: data,
    headers: {
      Authorization: Cookie.load('Authorization')
    }
  })
  return response.json()
}
