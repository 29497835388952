// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@4.3.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-datalist-toggler__wrapper--EBFMg .btn {\n  padding: 2px 12px;\n  height: 25px;\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://components/datalist/toggler.less"],"names":[],"mappings":"AAAA;EAGM,iBAAA;EACA,YAAA;EACA,eAAA;AADN","sourcesContent":[".wrapper {\n  :global {\n    .btn {\n      padding: 2px 12px;\n      height: 25px;\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "components-datalist-toggler__wrapper--EBFMg"
};
export default ___CSS_LOADER_EXPORT___;
