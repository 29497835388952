// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@4.3.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-panel-panel__panel--3LqB4 .ibox-title {\n  border-top-width: 1px;\n}\n.components-panel-panel__panel--3LqB4 .ibox-content {\n  position: relative;\n  border-color: #eaebef;\n}\n.components-panel-panel__panel--3LqB4 table:last-of-type {\n  margin-bottom: 0px;\n}\n", "",{"version":3,"sources":["webpack://components/panel/panel.less"],"names":[],"mappings":"AAAA;EAGM,qBAAA;AADN;AAFA;EAOM,kBAAA;EACA,qBAAA;AAFN;AANA;EAYM,kBAAA;AAHN","sourcesContent":[".panel {\n  :global {\n    .ibox-title {\n      border-top-width: 1px;\n    }\n\n    .ibox-content {\n      position: relative;\n      border-color: #eaebef;\n    }\n\n    table:last-of-type {\n      margin-bottom: 0px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "components-panel-panel__panel--3LqB4"
};
export default ___CSS_LOADER_EXPORT___;
