import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import {graphql, QueryRenderer} from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import Panel from 'components/panel'
import {fetchPermissions} from 'security/authorization'
import createForm, {Form, Input, File, Buttons, toForm} from 'components/forms'
import {Row, Col} from 'react-bootstrap'
import Spinner from 'components/spinner'
import Validation from 'utils/validation'
import ConfigurationHelper from './configuration-helper'
import SupermarketEditMutation from 'relay/mutations/SupermarketEditMutation'

class GeneralConfigurationFormComponent extends Component {
  render() {
    const {handleSubmit, pristine, submitting, valid, reset, loading} = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={!!loading}/>

        <Panel>
          <File name='image' horizontal label='Logotipo'/>
          <hr className='hr-line-dashed'/>
          <File name='favicon' horizontal accept='.ico, .vnd' label='Favicon'/>
          <hr className='hr-line-dashed'/>
          <Input name='websiteTitle' horizontal type='text' label='Título do site'/>
          <hr className='hr-line-dashed'/>
          <Input name='websiteDescription' horizontal type='text' label='Descrição do site'/>
          <hr className='hr-line-dashed'/>
          <Input name='keywords' horizontal type='text' label='Palavras chave'/>
          <hr className='hr-line-dashed'/>
          <Input name='customerAppToken' horizontal type='text' label='Token da área do cliente'/>
          <hr className='hr-line-dashed'/>
          <Input name='productApiToken' horizontal type='text' label='Token API (Repositório de Produtos)'/>

          <hr className='hr-line-dashed'/>

          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
          />
        </Panel>
      </Form>
    )
  }
}

const validation = new Validation({
  image: [Validation.required],
  favicon: [Validation.required],
  websiteTitle: [Validation.required],
  websiteDescription: [Validation.required],
  keywords: [Validation.required],
})

const GeneralConfigurationForm = createForm(GeneralConfigurationFormComponent, validation)

class GeneralConfigurationEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {permissions: {}}
  }

  async componentDidMount() {
    const permissions = await fetchPermissions(['configuration:general:edit'])
    this.setState({permissions})
  }

  _onSubmit({image, favicon, websiteTitle, websiteDescription, keywords, customerAppToken, productApiToken}) {
    SupermarketEditMutation.commit({
      image,
      favicon,
      websiteTitle,
      websiteDescription,
      keywords,
      customerAppToken,
      productApiToken
    }, {
      onCompleted: ({SupermarketEdit}) => this.props.notify('success', 'Sucesso', 'Alterações nas Configurações gerais foram salvas'),
      onError: (errors) => this.props.notify('error', 'Erro', 'Não foi possível salvar suas alterações')
    })
  }

  render() {
    const {loading, viewer} = this.props
    return (
      <Content
        title='Configurações Gerais'
        permission='configuration:general:edit'
        menu={{group: 'Configurações', link: 'Geral'}}
        breadcrumb={{
          title: 'Configurações Gerais',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/configuration/general', title: 'Configurações Gerais'},
            {title: 'Alterar'}
          ]
        }}>
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <GeneralConfigurationForm loading={loading}/>
            ) : (
              <GeneralConfigurationForm
                {...this.props}
                loading={loading}
                initialValues={toForm(viewer.supermarket)}
                onSubmit={values => this._onSubmit(values)}
              />
            )}
          </Col>
          <Col sm={5}>
            <ConfigurationHelper type='general'/>
          </Col>
        </Row>
      </Content>
    )
  }
}

const GeneralConfigurationEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query configurationGeneralEdit_Query {
        viewer {
          id
          supermarket {
            id
            favicon {
              id
              thumborized
              name
              url
            }
            image {
              id
              thumborized
              url
            }
            websiteTitle
            websiteDescription
            keywords
            customerAppToken
            productApiToken
          }
        }
      }
    `}
    render={({error, props: rendererProps}) => {
      if (error) {
        console.error(error)
      }

      if (rendererProps) {
        return (
          <GeneralConfigurationEdit
            {...rendererProps}
            {...props}
          />
        )
      }

      return null
    }}
  />
)

export default connect(null, {...appActionCreators})(GeneralConfigurationEditRenderer)
