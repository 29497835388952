import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import Panel from 'components/panel'
import { fetchPermissions } from 'security/authorization'
import createForm, { Buttons, File, Form, Input, toForm } from 'components/forms'
import { Col, Row } from 'react-bootstrap'
import Spinner from 'components/spinner'
import Validation from 'utils/validation'
import ConfigurationHelper from './configuration-helper'
import SupermarketSocialEditMutation from 'relay/mutations/SupermarketSocialEditMutation'

class SocialConfigurationFormComponent extends Component {
  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={!!loading} />

        <Panel>
          <File name='image' horizontal label='Imagem para compartilhamento' />
          <hr className='hr-line-dashed' />
          <Input name='facebook' type='text' label='Facebook' horizontal addon='https://facebook.com/' />
          <hr className='hr-line-dashed' />
          <Input name='twitter' type='text' label='Twitter' horizontal addon='https://twitter.com/' />
          <hr className='hr-line-dashed' />
          <Input name='instagram' type='text' label='Instagram' horizontal addon='https://www.instgram.com/' />
          <hr className='hr-line-dashed' />
          <Input name='youtube' type='text' label='Youtube' horizontal addon='https://www.youtube.com/' />
          <hr className='hr-line-dashed' />
          <Buttons horizontal pristine={pristine} submitting={submitting} valid={valid} reset={reset} />
        </Panel>
      </Form>
    )
  }
}

const validation = new Validation({
  image: [Validation.required]
})

const SocialConfigurationForm = createForm(SocialConfigurationFormComponent, validation)

class SocialConfigurationEdit extends Component {
  constructor (props) {
    super(props)

    this.state = { permissions: {} }
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['configuration:social:edit'])
    this.setState({ permissions })
  }

  _onSubmit ({ image, facebook, twitter, googleplus, youtube, instagram }) {
    SupermarketSocialEditMutation.commit({ image, facebook, twitter, googleplus, youtube, instagram }, {
      onCompleted: ({ SupermarketSocialEdit }) => this.props.notify('success', 'Sucesso', 'Configurações das Mídias sociais foram salvas'),
      onError: (errors) => this.props.notify('error', 'Erro', 'Não foi possível salvar suas alterações')
    })
  }

  render () {
    const { loading, viewer } = this.props
    return (
      <Content
        title='Mídias Sociais'
        permission='configuration:social:edit'
        menu={{ group: 'Configurações', link: 'Mídias Sociais' }}
        breadcrumb={{
          title: 'Mídias Sociais',
          items: [
            { url: '/dashboard', title: 'Dashboard' },
            { url: '/dashboard/configuration/social', title: 'Mídias Sociais' },
            { title: 'Alterar' }
          ]
        }}>
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <SocialConfigurationForm loading={loading} />
            ) : (
              <SocialConfigurationForm
                {...this.props}
                loading={loading}
                initialValues={toForm(viewer.supermarket.social || {})}
                onSubmit={values => this._onSubmit(values)}
              />
            )}
          </Col>
          <Col sm={5}>
            <ConfigurationHelper type='social' />
          </Col>
        </Row>
      </Content>
    )
  }
}

const SocialConfigurationEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query configurationSocialEdit_Query {
        viewer {
          id
          supermarket {
            id
            social {
              image {
               id
               thumborized
                url
              }
              facebook
              twitter
              instagram
              googleplus
              youtube
            }
          }
        }
      }
    `}
    render={({ error, props: rendererProps }) => {
      if (error) {
        console.error(error)
      }

      if (rendererProps) {
        return (
          <SocialConfigurationEdit
            {...rendererProps}
            {...props}
          />
        )
      }

      return null
    }}
  />
)

export default connect(null, { ...appActionCreators })(SocialConfigurationEditRenderer)
