import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import BannerEditMutation from 'relay/mutations/BannerEditMutation'

import Content from 'components/templates/dashboard/content'
import { toForm, toMutation } from 'components/forms'
import { message } from 'utils/messages'
import { routeTo } from 'router'
import { permission, title, route } from './utils'
import BannersForm from './banners-form'
import BannersHelper from './banners-helper'

class BannersEdit extends Component {
  state = {
    loading: false
  }

  _loading (loading) {
    this.setState({
      loading: loading
    })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Banner alterado com sucesso')
    this.props.history.push(routeTo(route(this.props.position)))
  }

  _error (message) {
    this.props.actions.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    BannerEditMutation.commit(
      Environment,
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({ BannerEdit }) => {
          this._loading(false)

          if (BannerEdit.error) {
            this._error(message(BannerEdit.error))
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error(message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    const { viewer, position } = this.props
    const companiesCount = viewer.companies.edges.length
    let companies = companiesCount === 1 ? (
      [viewer.companies.edges[0].node.id]
    ) : (
      viewer.banner.companies.edges.map(e => e.node.id)
    )

    return (
      <Content
        title={title(this.props.position, 'EDIT')}
        permission={permission(this.props.position, 'EDIT')}
        menu={{
          group: 'Banners',
          link: title(this.props.position)
        }}
        breadcrumb={{
          title: title(this.props.position),
          items: [
            { 'url': routeTo('dashboard'), 'title': 'Dashboard' },
            { 'url': routeTo(route(this.props.position)), 'title': title(this.props.position) },
            { 'title': 'Alterar' }
          ]
        }}
      >
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <BannersForm loading />
            ) : (
              <BannersForm
                loading={this.state.loading}
                viewer={viewer}
                position={position}
                initialValues={toForm({
                  ...viewer.banner,
                  companies
                })}
                onSubmit={(values) => this._onSubmit(values)}
              />
            )}
          </Col>

          <Col sm={5}>
            <BannersHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const BannersEditQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query bannersEdit_Query($id: ID!) {
        viewer {
          companies(selectableInMenu: true) {
            edges {
              node {
                id
                name
              }
            }
          }
          banner(id: $id) {
            id
            companies {
              edges {
                node {
                  id
                  name
                }
              }
            }
            title
            buttonCaption
            image {
              id
              key
              url
              thumborized
            }
            position
            order
            link
            linkTarget
            temporary
            start
            end
            days
            active
          }
        }
      }
    `}
    variables={{ id: props.match.params.id }}
    render={({props: rendererProps}) => {
      if (!rendererProps) {
        return null
      }

      return (
        <BannersEdit
          {...rendererProps}
          {...props}
        />
      )
    }}
  />
)

export default function BannersEditWithPosition (position) {
  const BannersEditWithPosition = (props) => (
    <BannersEditQueryRenderer
      {...props}
      position={position}
    />
  )

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(appActionCreators, dispatch)
  })

  return connect(null, mapDispatchToProps)(BannersEditWithPosition)
}
