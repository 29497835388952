// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@4.3.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-datalist-incrementer__incrementer--rASvf {\n  max-width: 110px;\n}\n.components-datalist-incrementer__incrementer--rASvf .input-group-btn .btn {\n  padding: 2px 12px;\n  height: 25px;\n  font-size: 10px;\n}\n.components-datalist-incrementer__incrementer--rASvf .form-control {\n  padding: 0px;\n  height: 25px;\n  font-size: 13px;\n  text-align: center;\n  background: white;\n  border: 1px solid #e7eaec;\n}\n", "",{"version":3,"sources":["webpack://components/datalist/incrementer.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAFA;EAMQ,iBAAA;EACA,YAAA;EACA,eAAA;AADR;AAPA;EAaM,YAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,yBAAA;AAHN","sourcesContent":[".incrementer {\n  max-width: 110px;\n\n  :global {\n    .input-group-btn {\n      .btn {\n        padding: 2px 12px;\n        height: 25px;\n        font-size: 10px;\n      }\n    }\n\n    .form-control {\n      padding: 0px;\n      height: 25px;\n      font-size: 13px;\n      text-align: center;\n      background: white;\n      border: 1px solid #e7eaec;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"incrementer": "components-datalist-incrementer__incrementer--rASvf"
};
export default ___CSS_LOADER_EXPORT___;
