import React, { Component } from 'react'

import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import config from './config'
import MenuHeader from './menu-header'
import MenuLink from './menu-link'
import MenuGroup from './menu-group'

class Sidebar extends Component {
  state = {
    menu: []
  }

  async _handlePermissions () {
    const permissions = []
    config.map(group => {
      if (group.permission) {
        permissions.push(group.permission)
      }

      const items = group.submenu || []
      items.map(item => {
        permissions.push(item.permission)
      })
    })
    const permissionsCall = await fetchPermissions(permissions)
    console.log(permissionsCall)
    return fetchPermissions(permissions)
  }

  _handleSingleItem (permissions, item) {
    const isPublic = !item.permission
    const hasPermission = permissions[item.permission]

    if (!isPublic && !hasPermission) {
      return null
    }

    return {
      ...item,
      url: routeTo(item.route)
    }
  }

  _handleItemWithSubmenu (permissions, item) {
    const itemWithSubmenu = {
      ...item,
      submenu: item.submenu.map(submenu => {
        return this._handleSingleItem(permissions, submenu)
      }).filter(subitem => {
        return subitem !== null
      })
    }

    if (itemWithSubmenu.submenu.length < 1) {
      return null
    }

    return itemWithSubmenu
  }

  async componentDidMount () {
    const permissions = await this._handlePermissions()

    const menu = config.map(item => {
      if (item.submenu) {
        return this._handleItemWithSubmenu(permissions, item)
      } else {
        return this._handleSingleItem(permissions, item)
      }
    }).filter(item => {
      return item !== null
    })

    this.setState({ menu })
  }

  render () {
    return (
            <nav className="navbar-default navbar-static-side" role="navigation">
              <div className="sidebar-collapse">
                <ul className="nav" id="side-menu">
                  <MenuHeader/>

                  {
                    this.state.menu.map((item, index) => {
                      return (item.submenu ? (
                              <MenuGroup key={index} {...item} />
                      ) : (
                              <MenuLink key={index} {...item} />
                      ))
                    })
                  }
                </ul>
              </div>
            </nav>
    )
  }
}

export default Sidebar
