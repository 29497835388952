// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@4.3.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width: 480px) {\n  .components-forms-buttons__buttons--KKmnJ .btn {\n    width: 100%;\n  }\n  .components-forms-buttons__buttons--KKmnJ .btn:not(:last-child) {\n    margin-bottom: 10px;\n  }\n}\n", "",{"version":3,"sources":["webpack://components/forms/buttons.less"],"names":[],"mappings":"AAEI;EAAA;IACE,WAAA;EAAJ;EAEI;IACE,mBAAA;EAAN;AACF","sourcesContent":[".buttons {\n  :global .btn {\n    @media (max-width: 480px) {\n      width: 100%;\n\n      &:not(:last-child) {\n        margin-bottom: 10px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "components-forms-buttons__buttons--KKmnJ"
};
export default ___CSS_LOADER_EXPORT___;
