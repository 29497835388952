// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@4.3.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-section-collapse-styles__section-collapse--DtiZd {\n  margin-bottom: 0px;\n  padding-bottom: 10px;\n}\n.components-section-collapse-styles__toggler-title--hUDHd {\n  font-size: 18px;\n  font-weight: 100;\n  cursor: pointer;\n}\n.components-section-collapse-styles__toggler-content--quZNt {\n  border-top: 1px solid #e8e8e8;\n}\n", "",{"version":3,"sources":["webpack://components/section-collapse/styles.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oBAAA;AACF;AACA;EACE,eAAA;EACA,gBAAA;EACA,eAAA;AACF;AACA;EACE,6BAAA;AACF","sourcesContent":[".section-collapse {\n  margin-bottom: 0px;\n  padding-bottom: 10px;\n}\n.toggler-title {\n  font-size: 18px;\n  font-weight: 100;\n  cursor: pointer;\n}\n.toggler-content {\n  border-top: 1px solid rgb(232, 232, 232);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section-collapse": "components-section-collapse-styles__section-collapse--DtiZd",
	"toggler-title": "components-section-collapse-styles__toggler-title--hUDHd",
	"toggler-content": "components-section-collapse-styles__toggler-content--quZNt"
};
export default ___CSS_LOADER_EXPORT___;
