export function imageResizer (url, width, height) {
  return url
}

export function getApiRoot () {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:5000'
  }

  const hostname = window.location.hostname
  const prefix = 'admin.'
  if (hostname.indexOf(prefix) < 0) {
    throw new Error(`Not possible to determine the backend url for ${hostname}`)
  }

  const domain = hostname.substr(prefix.length)

  return `https://admin-api.${domain}`
}

export function getImageUploadUrl () {
  return `${getApiRoot()}/upload-image`
}

export function getPdfUploadUrl () {
  return `${getApiRoot()}/upload-pdf`
}

export function getGraphQLUrl () {
  return `${getApiRoot()}/graphql`
}
