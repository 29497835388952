import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'

import Spinner from 'components/spinner'
import Router from 'router'
import Notifications from './notifications'

class App extends Component {
  UNSAFE_componentWillMount () {
    console.log(this.props.viewer)
    this.props.actions.fetchHost(this.props.viewer.host)
  }

  render () {
    return (
      <div>
        <Notifications/>
        <Router/>
      </div>
    )
  }
}

const AppQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query appQuery {
        viewer {
          host {
            name
            aws {
              region
              bucket
            }
            customerAppToken
          }
        }
      }
    `}
    render={({ props: rendererProps }) => {
      if (!rendererProps) {
        return (
          <Spinner visible/>
        )
      }

      return (
        <App
          {...rendererProps}
          {...props}
        />
      )
    }}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(AppQueryRenderer)
